
import {
    LoanAndProperty
} from "@/models/pos/loan-and-property.model";
import {
    DemographicDetails
} from "@/models/pos/demographic-info.model";
import { Acknowledgement, AckDetail } from "@/models/pos/acknowledgement.model";
import {
    FinancialInfo
} from "@/models/pos/financial-info.model";
import {
    DeclarationDetails
} from "@/models/pos/declaration.model";
import {
    TransactionDetail
} from "@/models/pos/transaction-detail.model";
import Axios from 'axios';
import { BASE_API_URL } from '@/config';
import { BorrowerDetails, BorrowerInformation } from '@/models/pos/borrower-info.model';
import { Component, Vue } from 'vue-property-decorator';

import stores from "@/store/store";
import { borrowerData } from '@/models/borrowerData.model';
const store: any = stores;

@Component
export default class ApplyLoanMixin extends Vue {

    public isApplying = false;
    public lenderInfo: any = {};
    public borrowerData = new borrowerData();
    public newBorrowerInfo = [new BorrowerDetails()];
    public lenderComment = "";
    public fnmFile = null;
    public overlayLoader = false;
    public borrowerName = "";
    public showTitleOrderComponent = false;
    public source = null;
    public loanTxnId = null;

    public get posDataStore() {
        return store.state.questionnaireData;
    }
    public set posDataStore(data) {
        store.state.questionnaireData = data;
    }
    public async saveNewLoan(actionTypeObj, borrowerData) {
        if ((actionTypeObj.hasOwnProperty('type') && actionTypeObj.type != 'preview') || !actionTypeObj.hasOwnProperty('type')) {
            this.$store.state.wemloLoader = true;
        }
        try {
            

            if (!borrowerData.loanTxnId) {
                borrowerData.loanTxnId = this.$random(8);
            }
            let body = {
                borrowerInfo: { borrowerInfo: this.newBorrowerInfo },
                loanAndProperty: this.posDataStore.loanAndProperty,
                demographicInfo: this.posDataStore.demographicInfo,
                acknowledgement: this.posDataStore.acknowledgement,
                financialInfo: this.posDataStore.financialInfo,
                declaration: this.posDataStore.declaration,
                transactionDetail: this.posDataStore.transactionDetail,
                borrowerData: borrowerData, ...actionTypeObj
            };
            body.loanAndProperty.loanTxnId = body.borrowerInfo.loanTxnId =
                body.demographicInfo.loanTxnId = body.acknowledgement.loanTxnId = body.financialInfo.loanTxnId =
                body.declaration.loanTxnId = body.transactionDetail.loanTxnId = borrowerData.loanTxnId;
            /**Borrower Info */
            borrowerData.basicInformation.borrower.phone = body.borrowerInfo.borrowerInfo[0].personalInfo.contactInformation.cellPhone;
            borrowerData.basicInformation.borrower.email = body.borrowerInfo.borrowerInfo[0].personalInfo.contactInformation.email;
            borrowerData.basicInformation.borrower.firstName = body.borrowerInfo.borrowerInfo[0].personalInfo.name.firstName = body.borrowerInfo.borrowerInfo[0].personalInfo.name.firstName
                .charAt(0)
                .toUpperCase() +
                body.borrowerInfo.borrowerInfo[0].personalInfo.name.firstName
                    .substring(1)
                    .toLowerCase();
            borrowerData.basicInformation.borrower.lastName = body.borrowerInfo.borrowerInfo[0].personalInfo.name.lastName = body.borrowerInfo.borrowerInfo[0].personalInfo.name.lastName
                .charAt(0)
                .toUpperCase() +
                body.borrowerInfo.borrowerInfo[0].personalInfo.name.lastName
                    .substring(1)
                    .toLowerCase();
            body.borrowerInfo.borrowerInfo[0].personalInfo.hasAdditionalBorrower = borrowerData.basicInformation.hasCoBorrower ? 'true' : 'false';
            body.borrowerInfo.borrowerInfo[0].personalInfo.address.currentAddress.address = borrowerData.basicInformation.borrower.address
            //set the data of ssn no 
            body.borrowerInfo.borrowerInfo[0].personalInfo.ssn = borrowerData.basicInformation.borrower.ssn ? borrowerData.basicInformation.borrower.ssn : null;
            borrowerData.basicInformation.borrower.address.country = "";
            // Insert Data In loan object from data object
            body.loanAndProperty.loanAmount = borrowerData.loanInformation.loanAmount;
            body.loanAndProperty.propertyAddress.address = {
                zip: borrowerData.loanInformation.zipCode,
                state: borrowerData.loanInformation.state,
                county: borrowerData.loanInformation.county
            };
            body.loanAndProperty.amortization.type = borrowerData.loanInformation.amortizationType;
            if (borrowerData.loanInformation.purpose && borrowerData.loanInformation.purpose.ediValue == '16')
                body.loanAndProperty.loanPurpose = borrowerData.loanInformation.purpose;
            else {
                body.loanAndProperty.loanPurpose = { ppeValue: '3', name: 'Refinance', ediValue: '05', mismoValue: 'Refinance', value: 'Refinance' };
            }
            body.loanAndProperty.interestRate = borrowerData.loanInformation.rate;
            body.loanAndProperty.loanTerm = borrowerData.loanInformation.term;
            body.loanAndProperty.occupancy = borrowerData.loanInformation.occupancy;
            body.loanAndProperty.propertyAddress.propertyValue =
                borrowerData.loanInformation.propertyValue;

            if (borrowerData.loanInformation.productType && borrowerData.loanInformation.productType.value) {
                if (borrowerData.loanInformation.productType.value == 'FHA' || borrowerData.loanInformation.productType.value == 'USDA Rural' || borrowerData.loanInformation.productType.value == 'VA')
                    body.loanAndProperty.mortgageAppliedFor = borrowerData.loanInformation.productType;
            } else {
                body.loanAndProperty.mortgageAppliedFor = { ppeValue: '1', name: 'Conventional', ediValue: '01', mismoValue: 'Conventional', value: 'Conventional' } // conventional
            }
            let borrowerMapping = [];
            body.borrowerInfo.borrowerInfo.forEach((el, i) => {
                if (el.personalInfo.relationWithBorrower != 'Spouse') {
                    borrowerMapping.push({
                        borrowerIndex: i,
                        coBorrowerIndex: []
                    });
                }
            });
            body.borrowerInfo.borrowerInfo.forEach((el, i) => {
                if (el.personalInfo.relationWithBorrower == 'Spouse') {
                    borrowerMapping.forEach(e => {
                        if (e.borrowerIndex == el.personalInfo.jointWithCoBorrowerIndex) {
                            e.coBorrowerIndex.push(i);
                        }
                    })
                }
            });
            body.borrowerData.relationshipMapping = borrowerMapping;
            // assign all borrowers to other collections
            body.borrowerInfo.borrowerInfo.forEach((el, i) => {
                if (i != 0) {
                    body.demographicInfo.borrowerInfo.push(new DemographicDetails());
                    body.acknowledgement.borrowerInfo.push(new AckDetail());
                    body.declaration.borrowerInfo.push(new DeclarationDetails());
                }
            });

            //Sending data to the Server In Borrowers Collection
            let response = await Axios.post(
                BASE_API_URL + "broker/startNewLoan",
                body);
            this.$store.state.wemloLoader = false;
            return response;
        } catch (error) {
            this.$store.state.wemloLoader = false;
            console.log('Error', error);
        }
    }

    public async addOtherLender(loanTxnId, source, fromType) {
        try {
            this.isApplying = true;
            let formValid = await this.$validator.validateAll("otherLender");
            if (!formValid) {
                this.isApplying = false;
                return;
            }
            let otherLenderData = {
                lenderName:
                    this.lenderInfo.lenderNameAndId == "Others"
                        ? this.lenderInfo.lenderName
                        : this.lenderInfo.lenderNameAndId.lenderName,
                lenderId:
                    this.lenderInfo.lenderNameAndId == "Others"
                        ? null
                        : this.lenderInfo.lenderNameAndId._id,
                borrowerData: this.borrowerData,
                lenderWebsite: this.lenderInfo.lenderWebsite,
                lenderUserName: this.lenderInfo.lenderUserName,
                lenderPassword: this.lenderInfo.lenderPassword,
                accountExecutiveFirstName: this.lenderInfo.accountExecutiveFirstName,
                accountExecutiveLastName: this.lenderInfo.accountExecutiveLastName,
                accountExecutivePhone: this.lenderInfo.accountExecutivePhone,
                accountExecutiveEmail: this.lenderInfo.accountExecutiveEmail,
                comment: this.lenderComment,
                loanTxnId: loanTxnId
            };

            await this.applyLoan(null, otherLenderData, source, fromType);
            this.isApplying = false;
        } catch (error) {
            console.log(error);
        }
    }

    public async applyLoan(ppeData: any, otherLender: any, source: string, fromType: string) {
        try {

            let body = {
                ppeData: ppeData ? ppeData : null,
                otherLender: otherLender ? otherLender : null
            };
            let loanTxnId = source == "newLoan" ? this.$route.query.loanTxnId : otherLender.loanTxnId
            let formData = new FormData();
            if (this.fnmFile) formData.append("file", this.fnmFile);
            formData.append("borrowerData", JSON.stringify(body));
            formData.append("fromType", fromType);
            let response = await Axios.post(
                BASE_API_URL + "broker/applyNewLoan",
                formData,
                {
                    params: {
                        loanTxnId: loanTxnId,
                        userType: this.$store.state.sessionObject.type,
                        source: source,
                        brokerId: this.$brokerId
                    }
                }
            );
            this.overlayLoader = false;
            this.borrowerName = response.data.name;
            this.$snotify.success("New Loan Applied");
            this.isApplying = false;
            if (source == "newLoan") {
                this.showTitleOrderComponent = true;
                this.source = null;
            } else {
                this.hideOtherLenderModal(fromType);
            }
        } catch (error) {
            console.log(error);
            this.isApplying = false;
            this.overlayLoader = false;
        }
    }
    //Made New function for Apply New Loan  Need to remove applyLoan function
    public async apply_NewLoan(ppeData: any, otherLender: any, source: string) {
        try {
            let body = {
                ppeData: ppeData ? ppeData : null,
                otherLender: otherLender ? otherLender : null
            };
            let loanTxnId = source == "otherLender" ? otherLender.loanTxnId : this.$route.query.loanTxnId
            let formData = new FormData();
            if (this.fnmFile) formData.append("file", this.fnmFile);
            formData.append("borrowerData", JSON.stringify(body));
            let response = await Axios.post(
                BASE_API_URL + "broker/applyNewLoan",
                formData,
                {
                    params: {
                        loanTxnId: loanTxnId,
                        userType: this.$store.state.sessionObject.type,
                        source: source
                    }
                }
            );
            this.overlayLoader = false;
            this.borrowerName = response.data.name;
            this.$snotify.success("New Loan Applied");
            this.isApplying = false;
            this.$router.push({
                path: this.$getCurrentUserTypePath('apply-newloan'),
                query: { id: this.$route.query.loanTxnId }
            });


        } catch (error) {
            console.log(error);
            this.isApplying = false;
            this.overlayLoader = false;
        }

    }


    public attachFile(event) {
        this.fnmFile = event.target.files[0];
    }

    public hideOtherLenderModal(fromType) {
        this.lenderInfo = {};
        this.fnmFile = null;
        this.lenderComment = null;
        if (!this.$route.query.hasOwnProperty("edit")) {
            this.borrowerData = new borrowerData();
            this.$store.dispatch("resetPosData");

        }
        if (fromType == "brokerLoanDocuments") {
            this.$modal.hide("addOtherLender");
            this.$modal.show('titleInformation')
        } else {
            this.$modal.hide("addOtherLender");
            this.$modal.show("uploadDocs");
        }

    }


}

