


import { Component, Vue, Mixins } from "vue-property-decorator";
import Axios from "axios";
import stores from "@/store/store";
const store: any = stores;
import { BASE_API_URL } from "@/config";

import { HollowDotsSpinner } from "epic-spinners";
import { LoanAndProperty } from "../../../models/pos/loan-and-property.model";
import { borrowerData } from "@/models/borrowerData.model";

import ApplyLoanMixin from "../../../mixins/applyLoan.mixin";

@Component({ components: { HollowDotsSpinner } })
export default class PpeResultComponent extends Mixins(ApplyLoanMixin) {
  public errorMessageInFetchingRecord = null;
  public loanAmount = 0;
  public ppeResults = [];
  public lenders = [];
  public defaultLenderName = "Other Lender";
  public loanSifterViewStateData = "";
  public loanSifterSessionCookie = "";
  public isReset = false;
  public priceDetailsData: any = {};
  public priceDetailsProgramFindings = [];
  public wemloProcessingFee = false;
  public inProcessComplete = false;
  public brokerProfilePicture = null;

  // Default state list
  public get stateList() {
    return store.state.sessionObject.states;
  }
  //===============================================================================================
  //using dotloader on fetcinh ppr result
  public dotLoader = false;
  public loader = false;
  public loanTxnId = null;
  //===============================================================================================

  get loanSifterId() {
    return this.$store.state.loanSifterId;
  }

  public async getPpeResults(rate = null) {
    // this.dotLoader = true;
    this.$store.state.wemloLoader = true;
    try {
      this.errorMessageInFetchingRecord = null;
      let response = await Axios.post(
        BASE_API_URL + "ppe/getPpeResults",
        {
          brokerId: this.$brokerId,
          loanTxnId: this.$route.query.loanTxnId,
          loanSifterId: this.loanSifterId,
          rate: rate,
          userAgent: navigator.userAgent //It gives the userAgent of browser.
        });
      if (
        response.data.status == "success" &&
        response.data.hasOwnProperty("ppeResults")
      ) {
        if (response.data.hasOwnProperty("loanAmount")) {
          this.loanAmount = response.data.loanAmount;
        }
        if (response.data.hasOwnProperty("viewStateData")) {
          this.loanSifterViewStateData = response.data.viewStateData;
        }
        if (response.data.hasOwnProperty("sessionCookie")) {
          this.loanSifterSessionCookie = response.data.sessionCookie;
        }
        if (response.data.hasOwnProperty("wemloProcessingFee")) {
          this.wemloProcessingFee = response.data.wemloProcessingFee;
        }
        this.ppeResults = response.data.ppeResults;
      } else if (
        response.data.status == "success" &&
        !response.data.hasOwnProperty("ppeResults")
      ) {
        this.errorMessageInFetchingRecord = response.data.message;
      }
      if (
        response.data.status == "success" &&
        response.data.fetchedLoanSifterId
      ) {
        await this.$store.dispatch(
          "setloanSifterId",
          response.data.fetchedLoanSifterId
        );
      }
      // this.dotLoader = false;
      this.$store.state.wemloLoader = false;
    } catch (error) {
      console.log(error);
      // this.dotLoader = false;
      this.$store.state.wemloLoader = false;
    }
  }
  public async getBrokerDetails() {
    this.dotLoader = true;
    try {
      let response = await Axios.get(BASE_API_URL + "broker/getBrokerDetails", {
        params: { brokerId: this.$brokerId }
      });
      this.brokerProfilePicture = response.data.brokerDetail.profilePicture;
      this.dotLoader = false;
    } catch (error) {
      this.dotLoader = false;
      console.log(error.response);
    }
  }
  public async getPreQualLetter() {
    if (this.brokerProfilePicture != null) {
      this.$router.push({
        path: this.$getCurrentUserTypePath("pre-qualification"),
        query: { loanTxnId: this.$route.query.loanTxnId }
      });
    } else {
      this.$snotify.info("Broker has not uploaded the profile picture");
    }
  }

  public  applyNewLoan(
    lenderName,
    rate,
    loansifterPrice,
    wemloPrice,
    loansifterDiscPremCommission,
    wemloDiscPremCommission
  ) {
    this.overlayLoader = true;
    try {
      this.inProcessComplete = true;
      if (loansifterDiscPremCommission) {
        this.inProcessComplete = false;
        loansifterDiscPremCommission = loansifterDiscPremCommission.replace(
          /[^0-9.]|$/g,
          ""
        );
      }
      if (wemloDiscPremCommission) {
        this.inProcessComplete = false;
        wemloDiscPremCommission = wemloDiscPremCommission.replace(
          /[^0-9.]|$/g,
          ""
        );
      }
      let ppeData = {
        lenderName,
        rate,
        loansifterPrice,
        wemloPrice,
        loansifterDiscPremCommission,
        wemloDiscPremCommission
      };
      this.apply_NewLoan(
        ppeData,
        null,
        this.$route.query.hasOwnProperty("otherSite") ? "otherSite" : "newLoan"
      );
    } catch (error) {
      this.inProcessComplete = false;
      console.log(error);
    }
  }

  public attachFile(event) {
    this.fnmFile = event.target.files[0];
  }

 

  public async getPriceDetails(data) {
    try {
      this.$store.state.wemloLoader = true;
      data["viewStateValue"] = this.loanSifterViewStateData;
      data["sessionCookieValue"] = this.loanSifterSessionCookie;
      data["loanAmount"] = this.loanAmount;
      data["wemloProcessingFee"] = this.wemloProcessingFee;
      let response = await Axios.post(
        BASE_API_URL + "ppe/getLenderPriceDetails",
        data);
      if (
        response.data.status == "success" &&
        response.data.hasOwnProperty("priceDetailsData")
      ) {
        this.priceDetailsData = response.data.priceDetailsData;
        this.priceDetailsProgramFindings = [];
        if (this.priceDetailsData.programFindings) {
          let x = this.priceDetailsData.programFindings.split("<br>");
          x.forEach(element => {
            let y = element.replace("<b>", "");
            let z = y.replace("</b>", "");
            if (element != "") {
              this.priceDetailsProgramFindings.push(z);
            }
          });
        }
        this.$modal.show("priceDetails");
      } else if (response.data.status == "error") {
        this.$snotify.error(response.data.message);
      }
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error.response);
    }
  }
  public getUpdatedPpeResult(rate) {
    this.getPpeResults(rate);
    this.$modal.hide("priceDetails");
  }
  public async back() {
    this.$router.push("../broker/new-loan");
  }
  public returnBrokerId() {
    if (this.$store.state.sessionObject.type == "Broker") {
      return this.$brokerId;
    } else {
      return this.$store.state.sessionObject.userInfo.brokerId;
    }
  }


  mounted() {
    this.getPpeResults();
    this.loanTxnId = this.$route.query.id;
    this.getBrokerDetails();
  }
}
